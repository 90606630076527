<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 370 192"
    style="enable-background: new 0 0 370 192"
    xml:space="preserve"
  >
  <path class="st0" d="M369.5 60.5C367.6 44.8 356.7 37 343.4 32.6C325.5 26.8 307.6 27.6 290.1 34.8C269.8 43.2 255.1 57.4 249.5 79.8C245 97.7 250.2 111.3 267.8 117.4C277.6 120.9 287.7 122.1 297.9 122.2C319.1 122 338.3 115.5 354.5 101.1C366.6 90.4 371.3 76.5 369.3 60.4L369.5 60.5ZM319 72.8C317 78.2 312.2 81.6 308.3 81.7C303 81.8 300.3 80.2 299.2 76.5C297.9 72.5 299.4 68.2 303 65.9C307 63.2 311.3 62.8 315.7 64.6C319.4 66.1 320.5 69 319 72.8Z" />
  <path class="st0" d="M248.2 34C246 59.6 240.9 84.4 227.7 106.8C225 111.4 221.5 115.5 217.3 118.8C209.1 125.1 200 125.5 190.7 123C185 121.5 182.1 116.8 181.7 110.7C181.2 102.7 183 95 185.1 87.4C188.5 74.9 191.7 62.2 196.8 50.3C201.8 38.5 206.4 26.3 214.7 16.2C218.7 11.4 223.3 7.69999 229.5 6.19999C237.4 4.39999 242.6 7.19998 245.6 14.9C248 21 248.2 27.5 248.4 34H248.2Z" />
  <path class="st0" d="M131 122.5C130 122.5 129 122.5 127.9 122.5C116.1 121.8 111.1 115.8 112.5 103.7C114 90 118 77 123.9 64.7C131.1 49.6 143.1 42.1 159.5 42C170.6 42 176.6 48.1 176.2 59.6C176.1 64.3 175.1 69 174 73.6C171.5 84.1 168.5 94.4 163.2 103.8C156 116.4 145.2 122.8 131 122.6V122.5Z" />
  <path class="st0" d="M159.1 35.8C154.6 35.8 150.2 35.7 145.9 34.1C137.9 31.1 135.7 24.6 140.4 17.2C144.3 11 150.1 7.19998 156.6 4.59998C164.3 1.49998 172.3 -2.26945e-05 180.5 0.599977C190.5 1.29998 194.9 7.79998 192 18C189.4 27.1 182.7 31.4 174.5 33.9C169.5 35.4 164.3 36 159 35.9L159.1 35.8Z" />
  <path class="st0" d="M119.5 28.8C119.3 22.1 117.5 16.1 111.9 11.9C96.6 0.300001 72.9 8.7 68 27.4C65.9 35.7 65.4 44.3 63.8 52.7C63.6 53.7 63.4 54.5 63.2 55.1C70.2 60.2 72.6 67.3 72.9 68.3C73.2 69 74.8 73.2 73.3 79.8C72.5 83.4 71 85.5 68.9 86.1C68.5 86.2 68.1 86.3 67.7 86.3C65 86.3 62.7 84 62.2 83.5C60.8 82.3 59.4 81.6 57.9 81.3C58.5 82.2 58.9 83.3 59 84.6C59.5 92.9 55 97.8 46 97.8C43.4 97.7 40.2 97.8 38.9 94C37.7 90.3 39.6 84.5 42.9 82C44.4 80.9 46 80.1 47.6 79.7C48.4 79.3 57.1 75.5 63.9 81.5H64C64.6 82.2 66.6 84 68.1 83.6C69.5 83.2 70.2 81.1 70.6 79.3C72 73.2 70.4 69.6 70.4 69.6V69.4C70.4 69.4 67.7 61.1 59.8 56.4C59.4 56.2 59 56 58.5 55.7C44.4 47.5 30.5 49.7 17.5 57.9C4.80002 65.9 1.10001 79.1 0.700013 93.6C0.400013 104.9 5.20001 113.4 14.7 118.9C22.9 123.6 31.8 124.7 42.5 124.7C45.2 124.9 49.4 124.3 53.6 123.9C72.9 121.8 88.3 113.3 98.8 96C106.7 83 111.4 68.7 115.5 54.2C117.8 46.1 119.6 37.9 119.2 29.4L119.5 28.8ZM67.7 72.4C67.5 72.9 66.6 72.9 65.7 72.5C65.7 72.5 65.6 72.5 65.5 72.4C64.5 71.9 64 71 64.2 70.5C64.5 70 65.5 70 66.4 70.5C67.4 71 67.9 71.9 67.7 72.4ZM53.4 58.3C54.6 56.5 58.4 57 61.9 59.5C65.4 62 67.3 65.4 66.1 67.2C65.2 68.6 62.7 68.6 60 67.4C59.2 67 58.4 66.6 57.6 66C54.1 63.5 52.2 60.1 53.4 58.3ZM44.7 61C45.2 59.9 47.3 59.9 49.2 61C51.2 62 52.3 63.7 51.8 64.8C51.3 65.8 49.6 65.9 47.8 65.1C47.6 65.1 47.5 64.9 47.3 64.8C45.3 63.8 44.2 62 44.7 61Z" />
  <path class="st1" d="M94.3999 177.7H80.3999V139H94.3999C106.7 139 114.3 147.7 114.3 158.3C114.3 168.9 106.6 177.6 94.3999 177.6V177.7ZM94.3999 145.8H88.6999V170.9H94.3999C101.5 170.9 106 165.3 106 158.4C106 151.5 101.6 145.9 94.3999 145.9V145.8Z" />
  <path class="st1" d="M144.6 172.7C140.7 177.1 135.5 178.3 131.8 178.3C121.6 178.3 116 171.7 116 163.7C116 155.7 122.3 148.8 131.2 148.8C140.1 148.8 146.1 154.2 146.1 165.5H124C124.2 169 127.3 171.6 132.4 171.6C137.5 171.6 138.1 170.2 140 168.2L144.6 172.7ZM124.1 160.4H137.9C137.4 156.8 133.8 155.4 131.1 155.4C128.4 155.4 125.2 156.6 124.1 160.4Z" />
  <path class="st1" d="M150.9 177.7V137.7H158.9V177.7H150.9Z" />
  <path class="st1" d="M170 137.1C172.8 137.1 175.1 139 175.1 141.8C175.1 144.6 172.8 146.4 170 146.4C167.2 146.4 165 144.5 165 141.8C165 139.1 167.1 137.1 170 137.1ZM165.9 177.7V149.3H173.9V177.7H165.9Z" />
  <path class="st1" d="M197.5 177.7H189.6L178.1 149.3H186.8L193.5 168.4L200.3 149.3H209L197.5 177.7Z" />
  <path class="st1" d="M238.6 172.7C234.7 177.1 229.5 178.3 225.8 178.3C215.6 178.3 210 171.7 210 163.7C210 155.7 216.3 148.8 225.2 148.8C234.1 148.8 240.1 154.2 240.1 165.5H218C218.2 169 221.3 171.6 226.4 171.6C231.5 171.6 232.1 170.2 234 168.2L238.6 172.7ZM218.1 160.4H231.9C231.4 156.8 227.8 155.4 225.1 155.4C222.4 155.4 219.2 156.6 218.1 160.4Z" />
  <path class="st1" d="M252.5 155.5C253.4 150.4 257.7 148.7 260.1 148.7C262.5 148.7 261.3 148.7 262.1 148.9V157C261 156.7 259.5 156.6 258.7 156.6C255.7 156.6 252.7 158.4 252.7 162.2V177.6H244.7V149.2H252.5V155.4V155.5Z" />
  <path class="st1" d="M285.4 149.3H294.2L278.5 185.9L272.5 182.7C274.6 177.5 274.6 175.3 274.6 175.3L263.3 149.3H272.3L278.8 167L285.3 149.3H285.4Z" />
  <path class="st1" d="M272.5 182.7C272.2 183.5 271.5 184.2 270.7 184.6L270.368 184.8C269.568 185.3 268.7 185.1 267.9 184.8C265.4 183.9 263.9 180.9 264 178.3C264 178.1 264 177.8 264 177.6C264 177.6 264.3 175.3 265.9 173.6C265.9 173.6 266.2 173.3 266.4 173.1C266.6 172.9 266.7 172.6 266.6 172.4C266.4 172.1 266.2 172 265.9 172.1C265.7 172.1 265.3 172.4 265.1 172.4C260.3 174.7 258.2 180.5 260.3 185.5C261.6 188.4 264.3 190.8 267.6 191.4C269.5 191.7 271.8 191.6 273.6 190.8C273.6 190.8 277.5 188.9 278.5 185.9C278.5 185.9 272.7 182.6 272.7 182.7H272.5ZM261.6 176.8C261.6 176.8 261.5 176.8 261.5 176.7C261.5 176.5 261.5 176.3 261.5 176.2C261.6 176 261.9 175.8 262 175.9C262.1 175.9 262.2 176.3 262 176.5C261.9 176.8 261.6 176.9 261.5 176.8H261.6ZM262.5 176.9C262.5 177 262.3 177 262.2 176.9V176.7C262.2 176.7 262.3 176.6 262.4 176.6C262.4 176.6 262.4 176.8 262.4 176.9H262.5ZM263.4 175.4C263 175.8 262.5 175.9 262.2 175.6C262 175.3 262.2 174.8 262.5 174.5C262.9 174.1 263.4 174 263.7 174.3C263.9 174.6 263.8 175.1 263.4 175.5V175.4Z" />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  position: relative;

  .st0 {
    fill: #FF1744;
  }
  .st1 {
    fill: #fff;
  }
}
</style>
