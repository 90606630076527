<template>
  <v-container align-center justify-center fill-height pa-4>
    <v-layout text-center align-center justify-center column>
      <div>
        <LogoOnboarding style="width: 200px; margin-bottom: 20px" />
        <v-card :loading="loading" class="px-4 pb-4">
          <v-card-title class="pa-5">
            <span class="display-1 font-weight-bold px-5">
              <span class="font-weight-light">Sistema</span>
              Administrativo
            </span>
          </v-card-title>
          <v-card-subtitle
            >Ingresa tu dirección de correo y contraseña para
            ingresar.</v-card-subtitle
          >
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                ref="mailAddress"
                type="email"
                label="Correo Electrónico"
                hint="correo@servidor.tldn"
                autocomplete="email"
                rounded
                filled
                clearable
              ></v-text-field>

              <v-text-field
                v-model="password"
                autocomplete="current-password"
                :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="Contraseña"
                hint="la contraseña debe tener más de 8 carácteres"
                rounded
                filled
                clearable
                @click:append="show1 = !show1"
              ></v-text-field>

              <div
                style="
                  display: flex;
                  justify-content: center;
                  margin-bottom: 20px;
                "
                id="recaptcha-container"
              ></div>

              <v-btn
                color="primary"
                block
                large
                id="sign-in-button"
                :elevation="0"
                :minHeight="56"
                :loading="loading"
                :disabled="loading"
                class="border-radius-main"
                >Ingresar</v-btn
              >
            </v-form>
            <v-card-actions>
              <v-btn
                @click="passReset"
                color="gray"
                dense
                block
                large
                text
                :elevation="0"
                :loading="loading"
                :disabled="loading"
                >Olvidaste tu contraseña?</v-btn
              >
            </v-card-actions>
            <p
              class="mt-2 mb-0 grey--text text--darken-2"
              style="font-size: 12px; line-height: 14px !important"
            >
              ¿problemas para ingresar?
              <br />contacta a nuestro
              <a
                href="https://wa.me/50433248888/?text=Hola%21%2C%20tengo%20problemas%20al%20ingresar%20a%20la%20plataforma%20web%20administrativa%20"
                v-if="isMobile()"
                >servicio al cliente</a
              >
              <a href="tel:+50433248888" v-else>servicio al cliente</a>
            </p>
          </v-card-text>
        </v-card>
        <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
          snackbarText
        }}</v-snackbar>
      </div>
      <p class="white--text disclaimer-text mt-4" style="z-index: 9999">
        <span>Versión de plataforma: {{ version }}</span>
        <br />

        <span
          >&copy; {{ new Date().getFullYear() }}, Servicios digitales GUIP S.A.</span
        >
        <br />
      </p>
    </v-layout>
    <!-- <v-snackbar
      absolute
      top
      multi-line
      :timeout="3000"
      v-model="snackbar"
      class="text-center"
    >{{ snackbarText }}</v-snackbar>-->

    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="500px">
      <otp
        :auth="authObject"
        ref="code"
        @cancel="otpDialog = false"
        @success="otpConfirmed"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
// import LogoBasa from "../components/LogoBasa"; // JoseV.
import LogoOnboarding from "../components/LogoOnboarding";
import mobile from "is-mobile";
// import { mapState } from "vuex";

// import * as moment from "moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import axios from "axios";

import otp from "../views/settings/insertCode.vue";

export default {
  name: "login",
  components: {
    // Never mounted JoseV.
    // LogoBasa,
    LogoOnboarding,
    otp,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      snackbar: false,
      snackbarText: null,
      show1: false,
      loading: true,
      email: "",
      password: "",
      valid: true,
      authObject: null,
      verificationId: null,
      recaptchaVerifier: null,
      otpDialog: false,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        // emailMatch: () => "The email and password you entered don't match",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
    };
  },
  computed: mapState(["userIsValid"]),
  watch: {
    userIsValid(newValue) {
      if (newValue === false) {
        this.loading = false;
      }
    },
  },
  methods: {
    ...mapActions(["addBusiness", "addCorporative", "addMenu"]),
    isMobile() {
      return mobile();
    },

    async otpConfirmed(otp) {
      try {
        var cred = await fb.auth.PhoneAuthProvider.credential(
          this.verificationId,
          otp
        );

        var multiFactorAssertion =
          await fb.auth.PhoneMultiFactorGenerator.assertion(cred);

        await this.authObject.resolveSignIn(multiFactorAssertion);
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-verification-code":
            this.$refs.code.error();

            break;

          default:
            break;
        }
      }
    },

    async mfaLogin() {
      // this.recaptchaVerifier = new fb.auth.RecaptchaVerifier(
      //   "recaptcha-container"
      // );

      var phoneInfoOptions = {
        multiFactorHint: this.authObject.hints[0],
        session: this.authObject.session,
      };

      try {
        var phoneAuthProvider = new fb.auth.PhoneAuthProvider();

        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        this.otpDialog = true;
      } catch (error) {
        // document.getElementById("recaptcha-container").innerHTML = "";

        recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
      }
    },

    showMessage(msg) {
      this.snackbarText = msg;
      this.snackbar = true;

      grecaptcha.reset(window.recaptchaWidgetId);
    },

    onNext() {
      try {
        
      // console.log('onNext');
      if (this.email && this.password) {
        this.loading = true;
        // fb.auth()
        //   .setPersistence(fb.auth.Auth.Persistence.SESSION)
        //   .then(() => {
        fb.auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((response) => {
            // this.loading = false;
            this.addMenu([]);
            this.logAccess(response, "success");
          })
          .catch((error) => {
            console.log(error);
            this.logAccess(error, "failed");
            this.loading = false;

            switch (error.code) {
              case "auth/multi-factor-auth-required":
                this.authObject = error.resolver;
                this.mfaLogin();
                break;

              case "auth/invalid-email":
                this.showMessage(
                  "Por favor ingresa la dirección de correo correctamente"
                );
                break;

              case "auth/user-not-found":
                this.showMessage(
                  "No existen usuarios con este correo. Verifica tu dirección o ponte en contacto con el administrador."
                );
                break;

              case "auth/wrong-password":
                this.showMessage("Contraseña incorrecta.");
                break;

              case "auth/argument-error":
                this.showMessage(
                  "El correo y la contraseña deben ser campos validos"
                );
                break;

              default:
                this.showMessage(error.message);
                break;
            }

            // this.snackbar = true;
          });
        // });
      } else {
        this.snackbarText = "Llena todos los campos para continuar";
        this.snackbar = true;
      }
      } catch (error) {
        throw error;
      }
    },
    async logAccess(response, result) {
      let ip = await axios({
        url: "https://get.geojs.io/v1/ip/geo.json",
      });

      let data = {
        result,
        ip: ip.data ? ip.data : {},
        date: moment().tz("America/Tegucigalpa").toDate(),
        domain: document.domain,
      };

      if (result == "success") {
        data.uid = response.user.uid;
        data.email = response.user.email;
      } else {
        data.email = this.email;
        data.error = {
          code: response.code,
          message: response.message,
        };
      }

      await db.collection("accessLog").add(data);
    },
    passReset() {
      let emailAddress = this.email;
      fb.auth().languageCode = "es";

      if (this.$refs.mailAddress.validate()) {
        fb.auth()
          .sendPasswordResetEmail(emailAddress)
          .then(() => {
            // Email sent.
            console.log("email sent");
            this.snackbarText = "Correo enviado, revisa tu bandeja de entrada";
            this.snackbar = true;
          })
          .catch((error) => {
            console.log(error);
            this.snackbarText =
              "Error al enviar correo, verifica tu conexión a internet";
            this.snackbar = true;
            // An error happened.
          });
      } else {
        this.snackbarText =
          "Ingresa tu dirección de correo electrónico para enviarte un enlace de reinicio de contraseña";
        this.snackbar = true;
      }
    },
  },
  mounted() {
    this.addBusiness(null);
    this.addCorporative(null);
    this.loading = false;

    window.recaptchaVerifier = new fb.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
      callback: async () => {
        this.onNext();
      },
    });
    console.log('window.recaptchaVerifier:', window.recaptchaVerifier);
    recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  },
};
</script>

<style  src="@/main.scss" lang="scss" scoped>
.border-radius-main {
  border-radius: 15px !important;
}
</style>